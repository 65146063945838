import '../App.css'; // Import your App.css file
import '../Whatsapp.css';
import Navbar from '../Navbar';
import { Link } from 'react-router-dom';

const WhatsappPage = () => {
  return (
    <div>
      <Navbar />
      <div className='main-content'>
        <div className='border-bottom'>
          <div className='container text-center padding-top padding-bottom'>
            <div className='col-12-lg'>
              <h1 className='mediumBrownFontColour'>Join the Community</h1>
              <p>
                We will be using a WhatsApp "Community" to organize
                communications once we are at the resort.
              </p>
              <p>
                <img
                  style={{ width: 20 + '%' }}
                  src='/img/whatsappqr.png'
                ></img>
              </p>
              <a
                target='_blank'
                href='https://chat.whatsapp.com/HYxFsnlNBaX6fHNYvjprwx'
              >
                <button className='blueButton'>Follow this link to join</button>
              </a>
            </div>
          </div>
        </div>
        <div className='container padding-top padding-bottom'>
          <div className='col-12-lg'>
            <h1 className='mediumBrownFontColour'>New to WhatsApp?</h1>

            <p>
              WhatsApp can be downloaded to your phone and you register with
              your phone number.
            </p>
            <p>
              It is similar to messaging apps like Facebook Messenger, where it
              will use WiFi to communicate so you do not need to have roaming on
              your phone.
            </p>
            <p>
              Search for the app in your App Store or find the{' '}
              <a target='_blank' href='https://www.whatsapp.com/download/'>
                links to download here.
              </a>
            </p>
          </div>
          <div className='col-lg-12'>
            <h2 className='mediumBrownFontColour'>
              Using WhatsApp Communities
            </h2>
            <p>
              Once you have joined the community, you will be able to see all of
              the groups within the community via the “Communities” tab in the
              app.{' '}
            </p>
          </div>
          <div className='col-5-lg'>
            <div className='center-vertical'>
              <h3>View Groups</h3>
              <p>
                There will be multiple groups created for different purposes:
                Announcements, Q&A, Photos, Daily Plans, etc.{' '}
              </p>
              <p>
                You can view the description of any group to find out what it is
                about and choose if you want to join it.
              </p>
            </div>
          </div>
          <div className='col-6-lg'>
            <div className='whatsapp-imgs'>
              <img src='/img/whatsapp-groups.png'></img>
              <img src='/img/whatsapp-groupinfo.png'></img>
            </div>
          </div>
          <div className='col-5-lg padding-top'>
            <div className='center-vertical'>
              <h3>Create a Group</h3>
              <p>
                We can also use groups for different plans/excursions. If you
                have plans or have booked a day trip for one of the resort days,
                feel free to make a group and make it visible to everyone so
                whoever wants to join, can.
              </p>
            </div>
          </div>
          <div className='col-6-lg padding-top'>
            <div className='whatsapp-imgs'>
              <img src='/img/whatsapp-newgroup.png'></img>
              <img src='/img/whatsapp-newgroup2.png'></img>
            </div>
          </div>
          <div className='col-5-lg padding-top'>
            <div className='center-vertical'>
              <h3>Don't like notifications?</h3>
              <p>
                You can mute notifications for any group if you want to be able
                to see the messages but not receive push notifications to your
                phone.
              </p>
            </div>
          </div>
          <div className='col-6-lg padding-top'>
            <div className='whatsapp-imgs'>
              <img src='/img/whatsapp-mute.png'></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatsappPage;
