import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyDVJBj3TCOYqlU4g9sJNFkmvO-bDQD6XgQ',
  authDomain: 'wedding-website-cf23a.firebaseapp.com',
  projectId: 'wedding-website-cf23a',
  storageBucket: 'wedding-website-cf23a.appspot.com',
  messagingSenderId: '446412933452',
  appId: '1:446412933452:web:095945a8d2365582f127e9',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services (Authentication, Firestore)
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };
