import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import LoginPage from './Pages/LoginPage'; // Your login component
import HomePage from './Pages/HomePage'; // Your home component
import RSVPPage from './Pages/RSVPPage'; // Your RSVP component
import { useAuth } from './auth'; // Custom hook for authentication logic
import WhatsappPage from './Pages/WhatsappPage';
import AnnouncementsPage from './Pages/AnnouncementsPage';
import FAQPage from './Pages/FAQPage';
import ScrollToTop from './ScrollToTop';

function App() {
  const { isAuthenticated } = useAuth(); // Check if the user is authenticated

  return (
    <Router>
      <ScrollToTop />
      <Routes>
        {/* Default route to login page */}
        <Route
          path='/'
          element={isAuthenticated ? <Navigate to='/home' /> : <LoginPage />}
        />

        {/* Protected routes */}
        <Route
          path='/home'
          element={isAuthenticated ? <HomePage /> : <LoginPage />}
        />
        <Route
          path='/rsvp'
          element={isAuthenticated ? <RSVPPage /> : <LoginPage />}
        />
        <Route
          path='/whatsapp'
          element={isAuthenticated ? <WhatsappPage /> : <LoginPage />}
        />
        <Route
          path='/announcements'
          element={isAuthenticated ? <AnnouncementsPage /> : <LoginPage />}
        />
        <Route
          path='/faq'
          element={isAuthenticated ? <FAQPage /> : <LoginPage />}
        />

        {/* Catch-all route */}
        <Route path='*' element={<Navigate to='/' />} />
      </Routes>
    </Router>
  );
}

export default App;
