import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css'; // Import your App.css file

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className='nav-container'>
      <nav className='navbar container'>
        <Link to='/rsvp'>
          <button className='blueButton'>RSVP</button>
        </Link>
        <ul className={`nav-links ${isOpen ? 'active' : ''}`}>
          <li>
            <Link to='/home'>Home</Link>
          </li>
          <li>
            <Link to='/whatsapp'>WhatsApp Group</Link>
          </li>
          <li>
            <Link to='/faq'>FAQ</Link>
          </li>
          <li>
            <Link to='/announcements'>Announcements</Link>
          </li>
        </ul>
        <div className='hamburger' onClick={toggleMenu}>
          {isOpen ? '✖' : '☰'}
          {/* Display 'X' when open, hamburger when closed */}
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
