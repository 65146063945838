import React, { useState } from 'react';
import { auth } from '../firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import '../App.css'; // Import your App.css file

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(''); // State for error message

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      console.log('Logged in successfully');
      setErrorMessage('');
    } catch (error) {
      console.error('Login error:', error);
      // Check for specific error codes
      if (error.code === 'auth/invalid-credential') {
        setErrorMessage(
          'Tisk Tisk, please follow instructions: Use the email and password we specified in the wedding invitation email.'
        );
      } else {
        setErrorMessage('Login failed. Please try again.'); // Generic error message
      }
    }
  };

  return (
    <div className='login-page'>
      <div className='loginBoundingRectangle container'>
        <h1 className='mediumBrownFontColour'>Log In</h1>
        <p>
          You must log in with the credentials provided to view this website.
        </p>

        <form onSubmit={handleLogin}>
          <div className='loginScreenValueEntryBoxTitles'>Email</div>
          <input
            type='email'
            placeholder='the login email specified in your invite'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div className='loginScreenValueEntryBoxTitles'>Password</div>
          <input
            type='password'
            placeholder='the password specified in your invite'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {errorMessage && <p className='errorMessage'>{errorMessage}</p>}{' '}
          {/* Display error message */}
          <button type='submit' className='blueButton'>
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
