import '../App.css'; // Import your App.css file
import Navbar from '../Navbar';
import { Link } from 'react-router-dom';

const AnnouncementsPage = () => {
  return (
    <div>
      <Navbar />
      <div className='main-content'>
        <div className='border-bottom blue-bg'>
          <div className='container text-center padding-top padding-bottom'>
            <div className='col-12-lg'>
              <h1>Announcements</h1>
              <p>
                Any updates to the information provided prior to November 8th
                will be updated below. We will also email you the most important
                updates. Once we are in Mexico, all updates and communication
                will be via our WhatsApp Community, so be sure to download the
                app before the trip!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnnouncementsPage;
