import React, { useState, useEffect, useRef } from 'react';
import { db } from '../firebase';
import Navbar from '../Navbar';
import '../App.css'; // Import your App.css file
import '../RSVP.css';
import { useAuth } from '../auth.js';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { collection, getDocs } from 'firebase/firestore';

const RSVPPage = () => {
  // State to manage guest statuses
  const [guests, setGuests] = useState([]); // Array to hold guest data from Firestore
  const [message, setMessage] = useState('');
  const { user, isAuthenticated, loading } = useAuth();
  const [firebaseMessage, setFirebaseMessage] = useState('');
  const [messageFeedback, setMessageFeedback] = useState('');
  const [adminGuests, setAdminGuests] = useState([]);
  const [adminMessages, setAdminMessages] = useState([]);

  const getBackgroundColor = (status) => {
    switch (status) {
      case 'Attending':
        return '#c8e6c9'; // Light green for attending
      case 'Not Attending':
        return '#ffccbc'; // Light red for not attending
      case 'Unconfirmed':
        return '#ffe0b2'; // Light orange for unconfirmed
      default:
        return '#ffffff'; // Default white background
    }
  };

  useEffect(() => {
    if (loading || !user) return;

    const fetchGuestAndMessageData = async () => {
      try {
        const userDocRef = doc(db, user.email, 'RSVP');
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const data = userDoc.data();
          const guestData = data.Guests || {};
          const guestList = Object.keys(guestData).map((name) => ({
            name,
            status: guestData[name],
          }));
          setGuests(guestList);
          setMessage(data.Message || '');
        }
      } catch (error) {
        console.error('Error fetching guests or message:', error);
      }
    };

    const fetchAllGuests = async () => {
      try {
        // Replace with the actual list of user emails if available.
        const userEmails = [
          'chloe.stefanie.dahl@gmail.com',
          'ocarbonneau@hotmail.com',
          'ddahl@shaw.ca',
          'awdahl38@gmail.com',
          'superen1@telus.net',
          'nolanhehr@hotmail.com',
          'jnjsrob@shaw.ca',
          'zoomer.17.sr@gmail.com',
          'jamier2b@gmail.com',
          'victoria.potter@mymhc.ca',
          'dmcmarti1992@gmail.com',
          'christodd137@gmail.com',
          'krawchukjonathan@gmail.com',
          'kristen@canyonstateit.com',
          'find.a.byron@gmail.com',
          'lauren.shanti.ang@gmail.com',
          'dylanjoycemail@gmail.com',
          'wang.peter91@gmail.com',
          'sglover445@gmail.com',
          'patrick.m.walkowski@gmail.com',
          'robynmogavero@gmail.com',
          'iansaari42@gmail.com',
          'dydanielkim@outlook.com',
          'cole.raschpichler@gmail.com',
          'gtfox@gtfox.com',
          'rthreff@gmail.com',
          'vmrsto@gmail.com',
          'a.schwabe@live.com',
          'lianne29_@hotmail.com',
          'benarowland@gmail.com',
        ];

        let allGuests = [];
        let messages = [];

        for (const email of userEmails) {
          const userDocRef = doc(db, email, 'RSVP');
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            const docData = userDoc.data();

            // Collect guests
            const guestData = docData.Guests || {};
            const guestList = Object.keys(guestData).map((name) => ({
              name,
              status: guestData[name],
              email,
            }));
            allGuests = allGuests.concat(guestList);

            // Collect message if it exists
            if (docData.Message) {
              messages.push({ email, message: docData.Message });
            }
          }
        }

        setAdminGuests(allGuests); // Set all guests for the admin view
        setAdminMessages(messages); // Set all messages for the admin view
      } catch (error) {
        console.error('Error fetching all guests and messages:', error);
      }
    };

    if (user?.email === 'amanhehr@gmail.com') {
      fetchAllGuests();
    }

    fetchGuestAndMessageData();
  }, [user, loading]);

  const updateStatusInFirestore = async (guestName, newStatus) => {
    try {
      const userDocRef = doc(db, user.email, 'RSVP');
      await updateDoc(userDocRef, {
        [`Guests.${guestName}`]: newStatus,
      });
      setFirebaseMessage('');
      setFirebaseMessage('Update Successful!');
    } catch (error) {
      console.error('Error updating status in Firestore:', error);
      setFirebaseMessage('');
      setFirebaseMessage('Update Failed - refresh the page and try again.');
    }
  };

  // Handle status change
  const handleStatusChange = (index, event) => {
    const newStatus = event.target.value;
    const updatedGuests = [...guests];
    const guestName = updatedGuests[index].name; // Get the guest's name for Firestore update
    updatedGuests[index].status = newStatus; // Update the status for the specific guest
    setGuests(updatedGuests); // Update the state

    // Update Firestore with the new status
    updateStatusInFirestore(guestName, newStatus);
  };

  // Handle message input change
  const handleMessageChange = (event) => {
    setMessage(event.target.value); // Update message state
  };

  const saveMessageToFirestore = async () => {
    try {
      const userDocRef = doc(db, user.email, 'RSVP');
      await updateDoc(userDocRef, {
        Message: message,
      });
      setMessageFeedback('Message saved successfully!');
    } catch (error) {
      console.error('Error saving message:', error);
      setMessageFeedback('Error saving message - try again later.');
    }
  };

  if (loading) return <div>Loading...</div>;

  // Grouping adminGuests by email address
  const groupedGuests = adminGuests.reduce((acc, guest) => {
    const { email, name, status } = guest;
    if (!acc[email]) acc[email] = [];
    acc[email].push({ name, status });
    return acc;
  }, {});

  return (
    <div>
      <Navbar />
      <div className='main-content'>
        <div className='container'>
          <div className='col-8-lg padding-top'>
            <h1 className='mediumBrownFontColour'>RSVP</h1>
            <h2 className='mediumBrownFontColour'>by August 1, 2025</h2>
            <p>
              We are so excited to have you join us! Below you should see a list
              of all the guests in your invite, and the option to select each
              guest as Attending, Not Attending, or Unconfirmed. If this page
              isn’t working properly, please email us your RSVP info at
              amanhehr@gmail.com
            </p>
          </div>
          <div className='col-8-lg'>
            <div className='table'>
              <div className='table-row table-header'>
                <div className='table-name'>Guest</div>
                <div className='table-status'>RSVP Status</div>
              </div>
              {guests.map((guest, index) => (
                <div
                  className='table-row'
                  key={index}
                  style={{ backgroundColor: getBackgroundColor(guest.status) }} // Set background color
                >
                  <div className='table-name'>{guest.name}</div>
                  <div className='table-status'>
                    <select
                      value={guest.status || ''} // Ensure it has a default value
                      onChange={(event) => handleStatusChange(index, event)} // Handle status change
                      onClick={() => setFirebaseMessage('')}
                    >
                      <option value='Unconfirmed'>Unconfirmed</option>
                      <option value='Attending'>Attending</option>
                      <option value='Not Attending'>Not Attending</option>
                    </select>
                  </div>
                </div>
              ))}
            </div>
            <div className='firebase-message'>
              <span>{firebaseMessage || ''}</span>
            </div>
          </div>
        </div>

        <div className='brown-bg'>
          <div className='container'>
            <div className='col-8-lg'>
              <h2>Optional Details</h2>
              <p>
                Feel free to include your flight details, resort booking
                details, anything else you’d like us to know, or just send us a
                note! You can update this section at any time.
              </p>
              <textarea
                rows='4'
                cols='50'
                value={message}
                onChange={handleMessageChange} // Handle message change
                placeholder='Type your message here...'
                onClick={() => setMessageFeedback('')}
                style={{ width: '100%' }} // Optional: Make it full width
              />
              <button className='blueButton' onClick={saveMessageToFirestore}>
                Save
              </button>
              <div className='firebase-message'>
                <span>{messageFeedback || ''}</span>
              </div>
            </div>
          </div>
        </div>

        {/* Conditional Admin Section */}
        {user?.email === 'amanhehr@gmail.com' && (
          <div className='admin-rsvp-status'>
            <h2>All Guest RSVPs</h2>

            {/* Status headers, displayed only once at the top */}
            <div className='rsvp-status-columns'>
              <div className='column'>
                <h3>Unconfirmed</h3>
              </div>
              <div className='column'>
                <h3>Attending</h3>
              </div>
              <div className='column'>
                <h3>Not Attending</h3>
              </div>
            </div>
            {Object.entries(groupedGuests).map(([email, guestsByEmail]) => (
              <div key={email} className='rsvp-status-email-group'>
                <div className='email-group-box'>
                  <h3 className='guest-email'>{email}</h3>
                  <div className='rsvp-status-columns'>
                    <div className='column'>
                      {guestsByEmail
                        .filter((guest) => guest.status === 'Unconfirmed')
                        .map((guest, index) => (
                          <p key={index} className='guest-status unconfirmed'>
                            {guest.name}
                          </p>
                        ))}
                    </div>
                    <div className='column'>
                      {guestsByEmail
                        .filter((guest) => guest.status === 'Attending')
                        .map((guest, index) => (
                          <p key={index} className='guest-status attending'>
                            {guest.name}
                          </p>
                        ))}
                    </div>
                    <div className='column'>
                      {guestsByEmail
                        .filter((guest) => guest.status === 'Not Attending')
                        .map((guest, index) => (
                          <p key={index} className='guest-status not-attending'>
                            {guest.name}
                          </p>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <h2>Messages</h2>
            <div className='messages-section'>
              {adminMessages.length > 0 ? (
                adminMessages.map((entry, index) => (
                  <div key={index} className='message-entry'>
                    <p>
                      <strong>Email:</strong> {entry.email}
                    </p>
                    <p>
                      <strong>Message:</strong> {entry.message}
                    </p>
                  </div>
                ))
              ) : (
                <p>No messages available.</p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RSVPPage;
